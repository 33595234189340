import React, { Suspense, lazy } from "react";
import { Route, Routes, Navigate, Switch, Redirect } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import { Container } from "@mui/material";
import useGaTracker from "./useGaTracker";

const DailySicha = lazy(() => import("../components/daily-sicha/DailySicha"));
const Topics = lazy(() => import("../components/topics/Topics"));
const About = lazy(() => import("../components/about/About"));
const SearchSicha = lazy(() => import("../components/search-sicha/SearchSicha"));
const Login = lazy(() => import("../components/login/Login"));
const PageNotFound = lazy(() => import("../components/page-not-found/PageNotFound"));
const Download = lazy(() => import("../components/download/Download"));
const OtherOptions = lazy(() => import("../components/other-options/OtherOptions"));
const Notification = lazy(() => import("../components/notification/Notification"));
const Donate = lazy(() => import("../components/donate/Donate"));
const Dedication = lazy(() => import("../components/dedication/Dedication"));
const UploadSichosFiles = lazy(() => import("../components/admin/upload-sichos-files/UploadSichosFiles"));
const Managments = lazy(() => import("../components/admin/management/Management"));
const Dedications = lazy(() => import("../components/admin/dedications/Dedications"));
const Downloads = lazy(() => import("../components/admin/downloads/Downloads"));
const Receipts = lazy(() => import("../components/admin/receipts/Receipts"));
const ReceiptList = lazy(() => import("../components/admin/receipt-list/ReceiptList"));
const DownloadsList = lazy(() => import("../components/admin/downloads-list/DownloadsList"));
const SubscriberList = lazy(() => import("../components/admin/subscriber-list/SubscriberList"));
const DedicationControl = lazy(() => import("../components/admin/dedication-control/DedicationControl"));
const GetSichosList = lazy(() => import("../components/admin/get-sichos-list/GetSichosList"));
const AddAds = lazy(() => import("../components/admin/add-ads/AddAds"));
const DeleteTopics = lazy(() => import("../components/admin/delete-topics/DeleteTopics"));
const NotificationList = lazy(() => import("../components/admin/notification-list/NotificationList"));
const UploadTopics = lazy(() => import("../components/admin/upload-topics/UploadTopics"));
const UploadDS = lazy(() => import("../components/admin/upload-daily-sicha/UploadDS"));
const Admin = lazy(() => import("../components/admin/Admin"));
// const DedicationCanada = lazy(() => import("../components/dedication/DedicationCanada"));

// export default function RouterConfig(props) {
//   useGaTracker();

//   const children = (
//     <>
//       <Admin />
//       <Container className="container inline-grid">
//         <Route path="/admin" exact>
//           <Managments />
//         </Route>
//         <Route path="/admin/upload-files" exact>
//           <UploadSichosFiles />
//         </Route>
//         <Route path="/admin/topics" exact>
//           <UploadTopics />
//         </Route>
//         <Route path="/admin/upload-ds" exact>
//           <UploadDS />
//         </Route>
//         <Route path="/admin/dedications" exact>
//           <Dedications />
//         </Route>
//         <Route path="/admin/dedication-control" exact>
//           <DedicationControl setSpinner={props.setSpinner} />
//         </Route>
//         <Route path="/admin/downloads" exact>
//           <Downloads />
//         </Route>
//         <Route path="/admin/receipts" exact>
//           <Receipts setSpinner={props.setSpinner} />
//         </Route>
//         <Route path="/admin/receipt-list" exact>
//           <ReceiptList />
//         </Route>
//         <Route path="/admin/downloads-list" exact>
//           <DownloadsList />
//         </Route>
//         <Route path="/admin/subscribers-list" exact>
//           <SubscriberList />
//         </Route>
//         <Route path="/admin/notification-list" exact>
//           <NotificationList />
//         </Route>
//         <Route path="/admin/delete-topics" exact>
//           <DeleteTopics />
//         </Route>
//         <Route path="/admin/add-ads" exact>
//           <AddAds />
//         </Route>
//         <Route path="/admin/get-sichos-list" exact>
//           <GetSichosList />
//         </Route>
//       </Container>
//       </>
//   );

//   return (
//     <Suspense fallback={<div>loading...</div>}>
//       <Switch>
//         <Route path="/" exact={true}>
//           <DailySicha setSpinner={props.setSpinner} />
//         </Route>
//         <Route path="/dailysicha.htm">
//           <Redirect to="/" />
//         </Route>
//         <Route path="/search">
//           <SearchSicha auth={props.auth} />
//         </Route>
//         <Route path="/topics">
//           <Topics setSpinner={props.setSpinner} />
//         </Route>
//         <Route path="/dedication">
//           <Dedication setSpinner={props.setSpinner} />
//         </Route>
//         <Route path="/dedication-canada">
//           <Dedication setSpinner={props.setSpinner} canada />
//           {/* <DedicationCanada setSpinner={props.setSpinner} /> */}
//         </Route>
//         <Route path="/download">
//           <Download setSpinner={props.setSpinner} />
//         </Route>
//         <Route path="/about">
//           <About />
//         </Route>
//         <Route path="/login">
//           <Login setAuth={props.setAuth} />
//         </Route>
//         <Route path="/donate">
//           <Donate setSpinner={props.setSpinner} />
//         </Route>
//         <Route path="/notification">
//           <Notification setSpinner={props.setSpinner} />
//         </Route>
//         {/* <Route path="/notification">
//           <Subscription setSpinner={props.setSpinner} />
//         </Route> */}
//         <Route path="/other-options">
//           <OtherOptions setSpinner={props.setSpinner} />
//         </Route>
//         <Route
//           path="/donatecanada"
//           component={() => {
//             window.location.replace(
//               "https://www.thechai.com/templates/articlecco_cdo/aid/5596983/jewish/Donate-The-Daily-Sicha.htm"
//             );
//             return null;
//           }}
//         />
//         <ProtectedRoute path="/admin" child={children} />
//         <Route path="*">
//           <PageNotFound />
//         </Route>
//       </Switch>
//     </Suspense>
//   );
// }

export default function RouterConfig(props) {
  useGaTracker();

  const adminRoutes = (
    <>
      <Admin />
      <Container className="container inline-grid rtl">
        <Routes>
          <Route path="" element={<Managments />} />
          <Route path="upload-files" element={<UploadSichosFiles />} />
          <Route path="topics" element={<UploadTopics />} />
          <Route path="upload-ds" element={<UploadDS />} />
          <Route path="dedications" element={<Dedications />} />
          <Route path="dedication-control" element={<DedicationControl setSpinner={props.setSpinner} />} />
          <Route path="downloads" element={<Downloads />} />
          <Route path="receipts" element={<Receipts setSpinner={props.setSpinner} />} />
          <Route path="receipt-list" element={<ReceiptList />} />
          <Route path="downloads-list" element={<DownloadsList />} />
          <Route path="subscribers-list" element={<SubscriberList />} />
          <Route path="notification-list" element={<NotificationList />} />
          <Route path="delete-topics" element={<DeleteTopics />} />
          <Route path="add-ads" element={<AddAds />} />
          <Route path="get-sichos-list" element={<GetSichosList />} />
        </Routes>
      </Container>
    </>
  );

  return (
    <Suspense fallback={<div>loading...</div>}>
      <Routes>
        <Route path="/" element={<DailySicha setSpinner={props.setSpinner} />} />
        <Route path="/dailysicha.htm" element={<Navigate to="/" />} />
        <Route path="/search" element={<SearchSicha auth={props.auth} />} />
        <Route path="/topics" element={<Topics setSpinner={props.setSpinner} />} />
        <Route path="/dedication" element={<Dedication setSpinner={props.setSpinner} />} />
        <Route 
          path="/dedication-canada" 
          element={<Dedication setSpinner={props.setSpinner} canada />} 
        />
        <Route path="/download" element={<Download setSpinner={props.setSpinner} />} />
        <Route path="/about" element={<About />} />
        <Route path="/login" element={<Login setAuth={props.setAuth} />} />
        <Route path="/donate" element={<Donate setSpinner={props.setSpinner} />} />
        <Route path="/notification" element={<Notification setSpinner={props.setSpinner} />} />
        <Route path="/other-options" element={<OtherOptions setSpinner={props.setSpinner} />} />
        <Route 
          path="/donatecanada"
          element={() => {
            window.location.replace(
              "https://www.thechai.com/templates/articlecco_cdo/aid/5596983/jewish/Donate-The-Daily-Sicha.htm"
            );
            return null;
          }} 
        />
        <Route 
          path="/admin/*" 
          element={<ProtectedRoute child={adminRoutes} />} 
        />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Suspense>
  );
}