import { createContext } from "react";

const englishContext = createContext({
  isEnglish: false,
  setIsEnglish: (lang) => {}
});

const alertContext = createContext({
  isViewd: true,
  setIsViewd: (bool) => {}
});

const engDateDesc = createContext({
  desc: '',
  setDesc: (des) => {}
});

export { englishContext, alertContext, engDateDesc };