function setNotToLS(ids) {
  const nots = getNotFromLS();
  nots.push(ids);
  localStorage.setItem('notIds', JSON.stringify(ids));
}

function getNotFromLS() {
  const nots = localStorage.getItem('notIds');
  if (nots) {
    return JSON.parse(nots);
  }
  return [];
}

function getNotsNotViewd(ids) {
  const nots = getNotFromLS();
  return ids.filter(id => {
    return !nots.includes(id)
  });
}

function allNotsViewd(ids) {
  return getNotsNotViewd(ids).length === 0;
}

export { setNotToLS, getNotsNotViewd, allNotsViewd }