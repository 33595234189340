// import React from "react";
// import { Redirect, Route } from "react-router-dom";
// import { isAuthenticated } from '../components/login/loginService';

// function ProtectedRoute({ component, child, ...restOfProps }) {
//   const authenticated = isAuthenticated();

//   return (
//     <>
//       {authenticated ?<Route
//         children={child} />
//         : <Redirect to="/login" />
//       }
//     </>
//   );
// }

// export default ProtectedRoute;
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { isAuthenticated } from '../components/login/loginService';

function ProtectedRoute({ child }) {
  const authenticated = isAuthenticated();

  return authenticated 
    ? (child || <Outlet />) 
    : <Navigate to="/login" replace />;
}

export default ProtectedRoute;