import { makeStyles } from '@mui/styles';
import axios from 'axios';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: '50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: '10px',
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: '10px',
  },
  submit: {
    margin: '10px',
  },
}));

async function submit(email, password, remember, navigate, auth) {
  const res = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/auth/authenticate`, { email, password });
  if (res.status === 200) {
    auth(true);
    login(res.data, remember);
    navigate('/admin')

  } else {
    alert('דוא"ל או סיסמה אינם נכונים')
  }
}

function login(admin, remember) {
  const expiresAt = moment().add(admin.expiresIn, 'days');
  if (remember) {
    localStorage.setItem('id_token', admin.idToken);
    localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()));
  } else {
    sessionStorage.setItem('id_token', admin.idToken);
    sessionStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()));
  }
}

function logout(auth) {
  auth(false)
  localStorage.removeItem('id_token');
  localStorage.removeItem('expires_at');
  sessionStorage.removeItem('id_token');
  sessionStorage.removeItem('expires_at');
}

function isAuthenticated() {
  return moment().isBefore(getExpiration());
}

function getExpiration() {
  let expiration = localStorage.getItem('expires_at');
  if (!expiration) {
    expiration = sessionStorage.getItem('expires_at');
  }
  const expiresAt = JSON.parse(expiration);
  return moment(expiresAt);
}

export { useStyles, submit, isAuthenticated, logout }