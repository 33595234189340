export default class MyURL {
	URL = new URL(window.location);
	params = this.URL.searchParams;
	
	getUrl () {
		this.URL.search = this.params.toString();
		return this.URL.toString();
	}

	getParam (key) {
		return this.params.get(key);
	}
	
	getParams (keys) {
		return keys.map(k => this.params.get(k));
	}
	
	setParams (data) {
		for (const key in data) {
			this.params.set(key, data[key]);
		}
		// this.setURL();
	}

	appendParams (data) {
		for (const key in data) {
			this.params.append(key, data[key]);
		}
		// this.setURL();
	}

	deleteParam (key) {
		this.params.delete(key);
		// this.setURL();
	}

	setURL () {
		this.URL.search = this.params.toString();
		const newUrl = this.URL.toString();
		window.history.pushState(null, '', newUrl);
	}

	hasParam (key) {
		return this.params.has(key);
	}
}